import { Container, Typography, useTheme } from '@mui/material';
import { CredibleResources } from 'app/appendix/CredibleResources';
import { cyan } from '@mui/material/colors';
import anxiety_guideline from './assets/anxiety_self-help_guidelines.pdf';
import { BullyingSection } from './BullyingSection';
import { TopicAppendix } from './TopicAppendix';
import { ExtLinkNoUnderline } from './ExtLinkNoUnderline';

export function Appendix() {
  const theme = useTheme();

  const titleStyle = {
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  };

  return (
    <Container
      sx={{
        marginTop: theme.spacing(8),
      }}
    >
      <Typography color={cyan[700]} variant="h4" align="left" sx={titleStyle}>
        Appendices
      </Typography>
      <Typography paragraph>
        Here is a list of references referred to throughout the PiP Kids-Autism program. Where available, we have provided direct links.
        Click on the module titles below to see the references for each module. Some additional useful references have also been added.
      </Typography>


      <TopicAppendix
        title="Autism: The Fundamentals"
        summary={
          <>
          </>
        }
        contents={[
          {
            title: 'Spoon Theory',
            references: [
              <ExtLinkNoUnderline href="https://butyoudontlooksick.com/articles/written-by-christine/the-spoon-theory/">
                The Spoon Theory written by Christine Miserandino.
              </ExtLinkNoUnderline>
            ],
          },
          {
            title: 'Autism Language',
            references: [
              <ExtLinkNoUnderline href="https://www.cambridge.org/core/journals/the-british-journal-of-psychiatry/article/neurodevelopmental-disorders-and-neurodiversity-definition-of-terms-from-scotlands-national-autism-implementation-team/C6582CFF0E5E00F2F0F38F294A6659D1">
               Shah, P., Boilson, M., Rutherford, M., Prior, S., Johnston, L., Maciver, D., & Forsyth, K. (2022).{' '}
               Neurodevelopmental disorders and neurodiversity: Definition of terms from Scotland's National Autism Implementation Team. <i>The British Journal of Psychiatry, 221</i>(3), 577-579. doi:10.1192/bjp.2022.43
              </ExtLinkNoUnderline>
            ],
          },
        ]}
      />
      <TopicAppendix
            title="Be Involved and Encourage Autonomy"
            contents={[
              {
                title: 'Be Involved',
                references: [
                  <ExtLinkNoUnderline href="https://www.tandfonline.com/doi/abs/10.1080/00131911.2013.780009">
                      S. Wilder (2014) Effects of parental involvement on academic achievement: a meta-synthesis, Educational Review, 66:3, 377-397
                  </ExtLinkNoUnderline>,
                ],
              },
              {
                title: 'Help your child to learn to solve their own problems',
                references: [
                  <ExtLinkNoUnderline href="https://www.apa.org/topics/children/cognitive-problem-solving">
                    American Psychological Association. (2003). Problem-solving program teaches kids how to use their heads instead of their fists.
                </ExtLinkNoUnderline>,
                ],
              },
            ]}
          />

      <TopicAppendix
        title="Guide Your Child’s Behaviour"
        contents={[
          {
            title: 'Time Out as a Consequence',
            references: [
              <ExtLinkNoUnderline href="http://dx.doi.org/10.1037/amp0000449">
                Dadds, M. R., & Tully, L. A. (2019). What is it to discipline a child: What should it be? A reanalysis of time-out from the perspective of child mental health,
                attachment, and trauma. American Psychologist. Advance online publication.
              </ExtLinkNoUnderline>
            ],
          },
          {
            title: 'The ABCs and Key Facts About Behaviour',
            references: [
              <ExtLinkNoUnderline href="https://doi.org/10.1177/109830079900100202">
                Dunlap, G., & Fox, L. (1999). A demonstration of behavioral support for young children with autism. Journal of Positive Behavior Interventions, 1(2), 77-87.
              </ExtLinkNoUnderline>,
              <ExtLinkNoUnderline href="https://doi.org/10.1016/j.rasd.2010.11.012">
                Kodak, T., Fisher, W. W., Clements, A., Paden, A. R., & Dickes, N. R. (2011). Functional assessment of instructional variables: Linking assessment and treatment. Research in Autism Spectrum Disorders, 5(3), 1059-1077. 
              </ExtLinkNoUnderline>,
              <ExtLinkNoUnderline href="https://doi.org/10.1080/07317107.2021.1997295">
                Rogers, S. J., Vismara, L. A., & Dawson, G. (2021). Coaching parents of young children with autism: Promoting connection, communication, and learning. New York, NY, US: The Guilford Press. 
              </ExtLinkNoUnderline>,
               <ExtLinkNoUnderline href="https://doi.org/10.1177/0271121410377510">
                Blair, K. C., Lee, I., Cho, S., & Dunlap, G. (2011). Positive behavior support through family-school collaboration for young children with autism. Topics in Early Childhood Special Education, 31, 22-36.
             </ExtLinkNoUnderline>
            ],
          },
          {
            title: 'Intervening at the rumbling stage',
            references: [
              <ExtLinkNoUnderline href="">
                  Myles, B. S. & Southwick, J. (2005). Asperger Syndrome and Difficult Moments : Practical Solutions for Tantrums, Rage, and Meltdowns. Hamilton: Social Skills Solutions.
              </ExtLinkNoUnderline>,
              <ExtLinkNoUnderline href="https://www.autism.org.uk/advice-and-guidance/topics/behaviour/meltdowns/all-audiences">
                  National Autistic Society: Meltdowns  - a guide for all audiences.
              </ExtLinkNoUnderline>
            ],
          },
          {
            title: 'Getting through recovery after the storm',
            references: [
              <ExtLinkNoUnderline href="">
                  Myles, B. S. & Southwick, J. (2005). Asperger Syndrome and Difficult Moments : Practical Solutions for Tantrums, Rage, and Meltdowns. Hamilton: Social Skills Solutions.
              </ExtLinkNoUnderline>,
              <ExtLinkNoUnderline href="https://www.crisoregon.org/cms/lib/OR01928264/Centricity/Domain/45/The%20Cycle%20of%20Tantrums.pdf">
                  Myles, B. S. & Hubbard A. (2005). The Cycle of Tantrums, Rage, and Meltdowns in Children and Youth with Asperger Syndrome, HighFunctioning Autism, and Related Disabilities.  
              </ExtLinkNoUnderline>
            ],
          },
          {
            title: 'Getting through recovery after the storm',
            references: [
              <ExtLinkNoUnderline href="https://www.autismspectrum.org.au/uploads/documents/Aspect%20Practice/PBS/Aspect-Practice-PBS-the-Behaviour-form-Info-Sheet.pdf">
                ASPECT Positive Behaviour Support: Behaviour Form Information Sheet. 
              </ExtLinkNoUnderline>
            ],
          },
        ]}
      />
      <TopicAppendix
        title="Look After Yourself"
        contents={[
          {
            title: 'Parental Self Care and Self Compassion',
            references: [
              <ExtLinkNoUnderline href="https://emergingminds.com.au/resources/parental-self-care-and-self-compassion/">
                Kienhuis, M., & Avdagic, E. (2021). Parental self-care and self-compassion. Adelaide: Emerging Minds.
              </ExtLinkNoUnderline>
            ],
          },
          {
            title: 'Finding Your Tribe',
            references: [
              <ExtLinkNoUnderline href="https://www.youtube.com/watch?v=aWXO1pfAipU">
                “Have You Found Your Tribe?, Karen Irvani, TEDxBanbury” <i>YouTube</i>, uploaded by TEDx Talks, 23 April, 2020, https://www.youtube.com/watch?v=aWXO1pfAipU (Permissions: YouTube Terms of Service)
              </ExtLinkNoUnderline>
            ],
          },
          {
            title: 'Self-Management of Anxiety',
            references: [
              <ExtLinkNoUnderline href="https://doi.org/10.1016/j.jad.2016.07.024">
                Morgan, A. J., Chittleborough, P., & Jorm, A. F. (2016). Self-help strategies for sub-threshold anxiety: A Delphi consensus study to find messages suitable for population-wide promotion. Journal of Affective Disorders, 206, 68-76.
              </ExtLinkNoUnderline>
            ],
          },
          {
            title: 'Everyday Activities to help Autistic Children',
            references: [
              <ExtLinkNoUnderline href="">
                Sally Rogers, Geraldine Dawson & Laurie Vismara. (2013). An Early Start for Your Child with Autism: Using Everyday Activities to Help Kids Connect, Communicate, and Learn. Guilford Press.
              </ExtLinkNoUnderline>
            ],
          },
        ]}
      />
      
      <TopicAppendix
         title="Manage Everyday Emotions"
         contents={[
           {
             title: 'Help Your Child Understand their Emotions',
             references: [
               <ExtLinkNoUnderline href="https://www.scirp.org/journal/paperinformation?paperid=93799">
                  Havighurst, S., & Harley, A. (2007). Tuning in to kids: Emotionally intelligent parenting: Program manual. University of Melbourne.
              </ExtLinkNoUnderline>,
             ],
           }
         ]}
      />

      <TopicAppendix
        title="Show Affection and Acceptance"
        contents={[
          {
            title: 'Show Affection and Acceptance',
            references: [
              <ExtLinkNoUnderline href="https://iris.who.int/bitstream/handle/10665/42878/924159134X.pdf?sequence=1">
                World Health Organization. (2004). The importance of caregiver-child interactions for the survival and healthy development of young children: A review.
              </ExtLinkNoUnderline>,
            ],
          },
        ]}
      />
    

      <TopicAppendix
         title="Support Your Child’s Sensory Needs"
         contents={[
           {
             title: '',
             references: [
               <ExtLinkNoUnderline href="https://www.youtube.com/watch?v=aPknwW8mPAM">
                  “Can you make it to the end?”, <i>YouTube</i>, uploaded by National Autistic Society, 17 May, 2019, https://www.youtube.com/watch?v=aPknwW8mPAM (Permissions: YouTube Terms of Service)
              </ExtLinkNoUnderline>,
              <ExtLinkNoUnderline href="https://www.youtube.com/watch?v=fexHcGqKIUQ">
               “Young people explain stimming” <i>YouTube</i>, uploaded by Ambitious about Autism, 12 August, 2020, https://www.youtube.com/watch?v=fexHcGqKIUQ (Permissions: YouTube Terms of Service)
              </ExtLinkNoUnderline>,
             ],
           },
           {
            title: 'Understanding sensory processing',
            references: [
              <ExtLinkNoUnderline href="">
                 Myles, B. s., Chiles, P., & Dunn, W. (2000). Asperger Syndrome and Sensory Issues: Practical Solutions for Making Sense of the World.
             </ExtLinkNoUnderline>,
             <ExtLinkNoUnderline href="https://doi.org/10.3389/fnint.2012.00107">
              Robledo, J., Donnellan, A., & Strandt-Conroy, K. (2012). An exploration of sensory and movement differences from the perspective of individuals with autism. Frontiers in Integrative Neuroscience, 6, 107. 
             </ExtLinkNoUnderline>,
            ],
          },
          {
            title: 'Your child’s sensory profile',
            references: [
              <ExtLinkNoUnderline href="">
                 Lindsey Biel, OTR/L, and Nancy Peske. (2009). Raising a sensory smart child. The Definitive Handbook for Helping Your Child with Sensory Processing Issues. 
             </ExtLinkNoUnderline>,
             <ExtLinkNoUnderline href="">
                 Wilbarger, P., & Wilbarger, J. L. (1991). Sensory Defensiveness in Children Aged 2-12: An Intervention Guide for Parents and Other Caretakers: Avanti Educational Programs.
             </ExtLinkNoUnderline>,
            ],
          },
          {
            title: 'Repetitive behaviour and stimming',
            references: [
              <ExtLinkNoUnderline href="https://doi.org/10.1002/aur.124">
                 Boyd, B.A., Baranek, G.T., Sideris, J., Poe, M.D., Watson, L.R., Patten, E., & Miller, H. (2010). Sensory features and repetitive behaviors in children with autism and developmental delays. Autism Research, 3(2), 78-87. 
             </ExtLinkNoUnderline>,
             <ExtLinkNoUnderline href="https://doi.org/10.1007/s10803-014-2248-x">
                 Wigham, S., Rodgers, J., South, M., McConachie, H., & Freeston, M. (2015). The interplay between sensory processing abnormalities, intolerance of uncertainty, anxiety and restricted and repetitive behaviours in autism spectrum disorder. Journal of Autism and Developmental Disorders, 45(4), 943-952. 
             </ExtLinkNoUnderline>,
            ],
          },
          {
            title: 'Solutions for everyday sensory challenges',
            references: [
              <ExtLinkNoUnderline href="">
                 Fowler, S. (2007). Sensory Stimulation: Sensory focused activities for people with physical and multiple disabilities. London: Jessica Kingsley Publishers.
             </ExtLinkNoUnderline>,
             <ExtLinkNoUnderline href="https://doi.org/10.5014/ajot.2011.001370">
                 Kinnealey, M., Koenig, K. P., & Smith, S. (2011). Relationships between sensory modulation and social supports and health-related quality of life. Am J Occup Ther, 65(3), 320-327.
             </ExtLinkNoUnderline>,
             <ExtLinkNoUnderline href="">
                Victorian Office of the Senior Practitioner (2012), Positive solutions in practice: using sensory focused activities to help reduce restraint and seclusion. Melbourne: Victorian Government Department of Human Services.
            </ExtLinkNoUnderline>,
            <ExtLinkNoUnderline href="https://www.education.sa.gov.au/docs/support-and-inclusion/student,-health-and-disability-support/interoception-301-activity-guide.pdf">
              Lean, C., Goodall, E., Leslie, M., Milanese, L., May, H., and Heays, D. (2019) Interoception Activity Guide 301, Department for Education, South Australia. 
            </ExtLinkNoUnderline>,
            ],
          }
         ]}
      />
      
    </Container>
  );
}
